import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Row, Tooltip, notification, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckOutlined,
  FullscreenOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import SimpleTable from "../../pages/MarketWatch/SimpleTable/SimpleTable";
import ThemeInput from "../../components/ThemeInput";
import {
  newSelectedMarket,
  setSeletedMarket,
  setWatchListArray,
} from "../../store/slice/marketWatchSlice";
import dayjs from "dayjs";
import EndPoints from "../../APICall/EndPoints";
import APICall from "../../APICall";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "../../components/Socket";
import useGetWatchList from "../../hooks/useGetWatchList";
import {
  StyledTableWrapper,
  StyledDropDownItem,
  StyledMarketButtonAll,
  StyledMarketButton,
} from "./style";
import HeaderView from "./Components/HeaderView";
import DropdownWatchList from "./Components/DropdownWatchList";
import WatchListModal from "./Components/WatchListModal";
import DisconnectedModal from "../../components/Modal";
import { setScriptCall } from "../../store/slice/globleSlice";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

function MarketWatch() {
  const fullscreenRef = useRef(null);
  const queryClient = useQueryClient();
  // const { getWatchListByHook } = useGetWatchList();
  const dispatch = useDispatch();
  //state
  // const [selectedMarket, setSelectedMarket] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState({
    flage: false,
    index: null,
  });
  const [watchListModal, setWatchListModal] = useState(false);
  const socketContext = useSocket();
  const { doSend } = socketContext;

  //redux
  // const marketList = useSelector((state) => state.globleSlice.marketListData);
  // const visibleList = useSelector(
  //   (state) => state.marketWatchSlice.selectedMarket
  // );
  const watchList = useSelector(
    (state) => state?.marketWatchSlice?.watchListArray
  );
  const selectedMarket =
    useSelector((state) => state.marketWatchSlice?.seletctedMaketIndex) || 0;
  const webSocketConnection = useSelector(
    (state) => state?.webSocketSlice?.webSocket?.socket
  );
  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );

  const unsubscribeSymbol = useMemo(() => {
    return watchList?.[selectedMarket]?.scriptList?.map((el) => el?.symbol);
  }, [watchList, selectedMarket]);
  const getWatchList = async () => {
    let response = await APICall("get", EndPoints.getWatchList);
    return response.data?.data;
  };

  const {
    isLoading: watchListLoading,
    data: watchListData,
    error: watchListError,
  } = useQuery({
    queryKey: ["getWatchList"],
    queryFn: getWatchList,
  });

  const getPositions = async (obj) => {
    let payloadObj = {
      userId: Number(loginUserId),
    };

    let response = await APICall("post", EndPoints?.tradePosition, payloadObj);
    return response?.data?.data;
  };

  if (watchListError) {
    notification.error({ message: watchListError?.message });
  }

  const {
    isLoading: positionLoading,
    data: positionData,
    error: positionError,
  } = useQuery({
    queryKey: ["getPositionData"],
    queryFn: getPositions,
  });

  // life cycle
  useEffect(() => {
    queryClient.refetchQueries(["getWatchList"]);
    queryClient.invalidateQueries(["getPositionData"]);

    return () => {
      doSend({
        event: "unsubscribeToServerMarket",
        payload: unsubscribeSymbol,
      });
      dispatch(setScriptCall([]));
    };
  }, []);

  useEffect(() => {
    if (
      (!selectedMarket && selectedMarket !== 0) ||
      selectedMarket >= watchList?.length
    ) {
      dispatch(setSeletedMarket(0));
    }
  }, [selectedMarket]);

  useEffect(() => {
    const symbolCall = watchListData?.[selectedMarket]?.scriptList?.map(
      (el) => el?.symbol
    );
    if (symbolCall?.length > 0) {
      doSend({
        event: "subscribeToServerMarket",
        payload: symbolCall,
      });
    }
    dispatch(setScriptCall(symbolCall));
  }, [selectedMarket, watchListData]);

  useEffect(() => {
    if (watchListData) {
      let isDiff = compareArrays(watchList, watchListData);
      if (isDiff) {
        dispatch(setWatchListArray({ data: watchListData, type: "new" }));
      }
      // getWatchListByHook(watchListData);
    }
  }, [watchListData, positionData]);

  useEffect(() => {
    if (positionData?.length > 0) {
      let positionsymbol = positionData?.map((el) => el?.symbol);
      const newWatchlistData = watchList?.map((el) => {
        let scriptList = el?.scriptList?.map((script) => {
          let validPosition = positionsymbol?.includes(script?.symbol);
          if (validPosition) {
            let newPosition = positionData?.filter(
              (item) => item?.symbol === script?.symbol
            );
            return { ...script, positions: newPosition };
          } else {
            return { ...script };
          }
        });
        return { ...el, scriptList };
      });
      dispatch(setWatchListArray({ data: newWatchlistData, type: "new" }));
    }
  }, [positionData, watchListData]);

  //functions
  const handleScriptSearch = (e) => {
    setSearchValue(e?.target?.value);
  };

  const handleMarketButton = (index) => {
    let removeSymbol = watchList?.[selectedMarket]?.scriptList?.map(
      (el) => el?.symbol
    );
    if (removeSymbol?.length > 0) {
      doSend({
        event: "unsubscribeToServerMarket",
        payload: removeSymbol,
      });
    }

    dispatch(setSeletedMarket(index));

    // setDropdownOpen({ flage: false, index: null });
  };

  const showAllTables = () => {
    // setSelectedMarket("All");
  };

  const onClickFullScreen = async () => {
    fullscreenRef.current.requestFullscreen();
  };

  const handleOpenChange = (open, index) => {
    setDropdownOpen({ flage: open, index: index });
  };

  return (
    <>
      <div style={{ marginTop: "1.5rem" }}>
        <HeaderView positionData={positionData} />
        <StyledTableWrapper
          ref={fullscreenRef}
          style={{
            backgroundColor: "rgb(240, 242, 245)",
          }}
        >
          <>
            {/* <Row
            justify={window.innerWidth >= "1200px" ? "space-between" : "left"}
            gutter={[10, 10]}
            style={{
              width: "100%",
              marginTop: "2.5rem",
              height: "fit-content",
            }}
          >
            <Col xs={12} sm={8} md={6} lg={3} xl={2}>
              <StyledMarketButtonAll
                $isSelected={selectedMarket === "All"}
                dropdownRender={() => <DropdownRender />}
                onClick={showAllTables}
              >
                All
              </StyledMarketButtonAll>
            </Col>

            {marketList.length !== 0 &&
              marketList?.map((item, index) => {
                return (
                  <Col xs={12} sm={8} md={6} lg={3} xl={2} key={item.value}>
                    <StyledMarketButton
                      $isSelected={selectedMarket === item}
                      onClick={() => handleMarketButton(item)}
                    >
                      {item.label}
                    </StyledMarketButton>
                  </Col>
                );
              })}

            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={4}
              style={{
                marginLeft: window.innerWidth >= 1200 ? "auto" : "",
              }}
            >
              <ThemeInput
                required
                inputProps={{
                  placeholder: "Search anything here",
                  prefix: <SearchOutlined />,
                  onChange: handleScriptSearch,
                }}
                formProps={{
                  name: "search_text",
                }}
              />
            </Col>

            {
              <Col
                xs={24}
                sm={12}
                md={6}
                lg={15}
                xl={1}
                style={{ textAlign: "end" }}
              >
                <Tooltip title="Full screen">
                  <FullscreenOutlined
                    onClick={onClickFullScreen}
                    style={{
                      height: "3.5rem",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            }
          </Row> */}
          </>
          <Row style={{ marginTop: "1rem", gap: "2rem" }}>
            {watchList &&
              watchList?.map((item, index) => {
                return (
                  <Col key={index} xs={11} sm={7} md={6} lg={3} xl={2}>
                    <StyledMarketButtonAll
                      trigger={["hover", "click"]}
                      $isSelected={selectedMarket == index}
                      onClick={() => handleMarketButton(index)}
                      dropdownRender={() => (
                        <DropdownWatchList
                          item={item}
                          index={index}
                          setDropdownOpen={setDropdownOpen}
                          dropdownOpen={dropdownOpen}
                        />
                      )}
                      open={dropdownOpen?.flage && dropdownOpen?.index == index}
                      onOpenChange={(value) => handleOpenChange(value, index)}
                    >
                      {item?.name}
                    </StyledMarketButtonAll>
                    {/* <StyledDropDown
                    menu={{
                      items,
                    }}
                    placement="bottom"
                    arrow={{
                      pointAtCenter: true,
                    }}
                  >
                    <StyledMarketButton>bottom</StyledMarketButton>
                  </StyledDropDown> */}
                  </Col>
                );
              })}
            <Col xs={11} sm={7} md={6} lg={3} xl={3}>
              <StyledMarketButton onClick={() => setWatchListModal(true)}>
                + Add Watch-List
              </StyledMarketButton>
            </Col>
            <Col
              xs={11}
              sm={11}
              md={11}
              lg={6}
              xl={4}
              style={{
                marginLeft: window.innerWidth >= 1200 ? "auto" : "",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <ThemeInput
                required
                inputProps={{
                  placeholder: "Search anything here",
                  prefix: <SearchOutlined />,
                  onChange: handleScriptSearch,
                }}
                formProps={{
                  name: "search_text",
                }}
              />
              <Tooltip title="Full screen">
                <FullscreenOutlined
                  onClick={onClickFullScreen}
                  style={{
                    height: "3.5rem",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          {watchListModal && (
            <WatchListModal
              watchListModal={watchListModal}
              setWatchListModal={setWatchListModal}
            />
          )}
          {/* {selectedMarket === "All" ? (
            watchList.length !== 0 &&
            watchList?.map((item) => {
              if (!visibleList?.[item.value]) {
                return null;
              }
              return (
                <SimpleTable
                  key={item.name}
                  title={item.name}
                  searchValue={searchValue}
                  isLoading={watchListLoading}
                  watchListData={item?.scriptList}
                />
              );
            })
            <SimpleTable
              key={selectedMarket}
              title={watchList?.[selectedMarket]?.name}
              searchValue={searchValue}
              isLoading={watchListLoading}
              selectedMarket={selectedMarket}
              watchListData={watchList?.[selectedMarket]?.scriptList}
            />
          ) : ( */}
          <SimpleTable
            key={selectedMarket}
            title={watchList?.[selectedMarket]?.name}
            searchValue={searchValue}
            isLoading={watchListLoading}
            selectedMarket={selectedMarket}
            watchListData={watchList?.[selectedMarket]?.scriptList}
            positionData={positionData}
          />
          {/* )} */}
        </StyledTableWrapper>
      </div>
      <DisconnectedModal isOpen={!webSocketConnection} />
    </>
  );
}

export default MarketWatch;

function compareArrays(array1, array2) {
  if (array1?.length !== array2?.length) return true;

  for (let i = 0; i < array1?.length; i++) {
    const item1 = array1[i];
    const item2 = array2[i];

    // Find corresponding items by _id
    if (item1?._id !== item2?._id) return true;

    const scriptList1 = item1?.scriptList || [];
    const scriptList2 = item2?.scriptList || [];

    // Compare scriptList lengths
    if (scriptList1?.length !== scriptList2?.length) return true;

    // Create sets of symbols from both lists
    const symbols1 = new Set(scriptList1.map((script) => script.symbol));
    const symbols2 = new Set(scriptList2.map((script) => script.symbol));

    // If sets have different sizes, they can't be equal
    if (symbols1.size !== symbols2.size) return true;

    // Check if all symbols from list1 are in list2
    for (let symbol of symbols1) {
      if (!symbols2.has(symbol)) return true;
    }
  }

  return false; // No differences found in symbols
}

const DropdownRender = (menu) => {
  const token = theme.useToken().token;
  const dispatch = useDispatch();

  // redux
  const marketList = useSelector((state) => state.globleSlice.marketListData);
  const selectedlist = useSelector(
    (state) => state.marketWatchSlice.selectedMarket
  );

  // function
  const onClickMarketItem = (item) => {
    let obj = {
      ...selectedlist,
      [item.value]: !selectedlist[item.value],
    };

    dispatch(newSelectedMarket(obj));
  };

  return (
    <div
      style={{
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        paddingBlock: "0.5rem",
      }}
    >
      {marketList?.map((item, index) => {
        const selected = selectedlist?.[item.value];
        return (
          <StyledDropDownItem
            $selected={selected}
            onClick={() => onClickMarketItem(item)}
            key={item?.value}
          >
            <CheckOutlined
              style={{
                marginRight: "0.5rem",
                visibility: selected ? "visible" : "hidden",
              }}
            />
            {item?.label}
          </StyledDropDownItem>
        );
      })}
    </div>
  );
};
