import { Button, Form, Input, Modal, Row, Tooltip, notification } from "antd";
import { useEffect, useState } from "react";
import { WhatsappShareButton } from "react-share";
import { WhatsAppOutlined } from "@ant-design/icons";
import APICall from "../../../../APICall";
import EndPoints from "../../../../APICall/EndPoints";
import { UserListButtonexport } from "../../../../styles/commonStyle";
import { ResetPassForm } from "../style";

const ResetButton = ({ item }) => {
  // state
  const [resetPasswardModal, setResetPasswardModal] = useState({
    status: false,
    userId: null,
    id: null,
    name: null,
  });

  // function
  const handlepassward = () => {
    setResetPasswardModal({ status: false, id: null });
  };

  const passwardOk = () => {
    setResetPasswardModal({ status: false, id: null });
  };

  const resetPassward = (item) => {
    setResetPasswardModal({
      status: true,
      id: item?._id,
      userId: item?.userId,
      name: item?.accountName,
    });
  };
  return (
    <>
      <Tooltip placement="bottomLeft" title="Reset Password">
        <UserListButtonexport
          className="regularUserTableBtn"
          onClick={() => resetPassward(item)}
        >
          RP
        </UserListButtonexport>
      </Tooltip>
      <Modal
        title={`Reset Passward of ${resetPasswardModal?.name} (${resetPasswardModal?.userId})`}
        open={resetPasswardModal.status}
        onOk={passwardOk}
        onCancel={handlepassward}
        destroyOnClose={true}
        footer={null}
      >
        <ResetUserPassward
          id={resetPasswardModal?.id}
          userId={resetPasswardModal?.userId}
          name={resetPasswardModal?.name}
          setResetPasswardModal={setResetPasswardModal}
        />
      </Modal>
    </>
  );
};

export default ResetButton;

const ResetUserPassward = ({ id, userId, name }) => {
  const [form] = Form.useForm();

  // state
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [copy, setCopy] = useState(false);
  // const dispatch = useDispatch();
  const linkText = "https://www.9bison.in";
  const shareUrl = `UserId: ${userId}(${name}),\n\nNewpassward : ${userData?.newPassword},\n\nfor application download ${linkText}`;
  const title = "Passward is successfully reset for,\n\n";

  // life cycle
  useEffect(() => {
    if (copy) {
      setCopy(false);
    }
  }, []);

  // api call
  const onFinish = async (values) => {
    let obj = { ...values, userId: id };
    setLoading(true);
    try {
      let response = await APICall("post", EndPoints.resetPassward, obj);

      if (response?.status === 200 && response?.data) {
        notification.success({ message: response?.data?.message });
        setUserData(obj);
      } else {
        navigator.clipboard.writeText(response?.data?.message);
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      notification.error({ message: error?.message });
    } finally {
      setLoading(false);
    }
  };

  // function
  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${title}${shareUrl}, for application download ${linkText}`
    );
    setCopy(true);
  };

  return (
    <ResetPassForm
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Row justify={"center"}>
        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please input your new password!" },
            {
              min: 4,
              message: "Password must be at least 4 characters long!",
            },
          ]}
          style={{ width: "90%" }}
        >
          <Input.Password name="newPassword" />
        </Form.Item>
      </Row>
      <Row justify={"center"}>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Please confirm your password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The two passwords do not match!");
              },
            }),
          ]}
          style={{ width: "90%" }}
        >
          <Input.Password name="confirmPassword" />
        </Form.Item>
      </Row>
      <Row justify={"center"}>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ marginTop: "1rem" }}
          >
            Reset Passward
          </Button>
        </Form.Item>
      </Row>
      {userData !== null && (
        <Row justify={"center"} style={{ gap: "1rem", marginLeft: "1rem" }}>
          <Button onClick={handleCopy}>{copy ? "Copied!" : "Copy"}</Button>
          <WhatsappShareButton url={shareUrl} title={title}>
            <WhatsAppOutlined style={{ fontSize: "2rem" }} />
          </WhatsappShareButton>
        </Row>
        // <ShareAltOutlined
        //   style={{ fontSize: "2rem", marginLeft: "15rem" }}
        // />
      )}
    </ResetPassForm>
  );
};
