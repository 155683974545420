import React, { useMemo } from "react";
import ThemeCard from "../../components/ThemeCard";
import {
  Col,
  Collapse,
  Popconfirm,
  Row,
  Skeleton,
  Table,
  notification,
} from "antd";
import ThemeDatePicker from "../../components/ThemeDatePicker";
import closeImage from "../../assets/icons/closeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import EndPoints from "../../APICall/EndPoints";
import APICall from "../../APICall";
import {
  MemorizedPositionData,
  PositionAverage,
  PositionQuantity,
  PositionSocketData,
} from "../../components/SocketLiveData";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import MarketScriptList from "../../components/MarketScriptList/MarketScriptList";
import { formatToINR } from "../../components/FormateToINR/formatToINR";
import { useQuery } from "@tanstack/react-query";
import {
  resetPositionSocketSlice,
  setPositionConnect,
} from "../../store/slice/positionSocketSlice";
import {
  CardDetailText,
  CardTitleText,
  CloseImage,
  FooterItem,
  MarketTypeRadio,
  StyTable,
  StyleRow,
  StyledLabel,
  TableFooterContainer,
  TitleRow,
  options1,
  options2,
  style,
} from "./style";
import {
  ScriptName,
  TotalMTM,
  TotalOutstanding,
  TotalPNL,
} from "./Components/tradePositionComponent";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import dayjs from "dayjs";
import { valanBillDate } from "../../components/CommanFuction/CommanFunction";
import { StyledButton } from "../../styles/commonStyle";
import ValanComponant from "../../components/ValanComponant/ValanComponant";

function TradingPosition() {
  //state
  const [value, setValue] = useState({
    radioClient: 0,
    radioAll: 2,
    asOnDate: null,
  });
  const [closePositionLoading, setClosePositionLoading] = useState({
    load: false,
    index: null,
  });
  const [activeKey, setActiveKey] = useState([]);
  const [rolloverLoading, setRolloverLoading] = useState(false);
  const [position, setPosition] = useState([]);
  const { params, setSearchParams } = useGetQueryParams();
  const valueRef = useRef({});
  const currentPriceRef = useRef({});
  const dispatch = useDispatch();

  //redux
  const userIP = useSelector((state) => state.authSlice?.user?.userIP) || "";
  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );
  const loginAccountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const summaryPosition = useSelector(
    (state) => state?.globleSlice?.positionFromSummay?.userId
  );
  const filterUser = useSelector((state) => state?.globleSlice?.filterUsers);

  // api
  const getPositions = async (obj) => {
    let isAllow = filterUser?.includes(Number(params?.userId));
    if (!isAllow) {
      notification.error({ message: "User Id is not valid" });
      return [];
    }
    let payloadObj = {
      userId: Number(params?.userId),
      marketName: params?.marketName || null,
      symbolName: params?.symbolName || null,
      asOnDate: params?.asOnDate || null,
      // valan: params?.valan || null,
    };

    let response = await APICall("post", EndPoints?.tradePosition, payloadObj);
    return response?.data?.data;
  };

  const {
    isLoading,
    data,
    error: positionError,
  } = useQuery({
    queryKey: ["getPositionData", params],
    queryFn: getPositions,
    enabled: Object.keys(params)?.length > 0,
  });

  if (positionError) {
    notification.error({ message: positionError?.message });
  }

  // life cycle
  useEffect(() => {
    if (Object.keys(params)?.length == 0) {
      setSearchParams({ userId: loginUserId });
    }
  }, [params]);

  useEffect(() => {
    if (loginAccountType === "customer") {
      setValue((pre) => ({ ...pre, radioClient: 1 }));
    }
    setSearchParams({
      ...params,
      userId: params?.userId
        ? params.userId
        : summaryPosition
        ? summaryPosition
        : loginUserId,
    });
    if (params?.asOnDate) {
      setValue((pre) => ({ ...pre, asOnDate: dayjs(params?.asOnDate) }));
    }
    dispatch(setPositionConnect(true));

    return () => {
      dispatch(resetPositionSocketSlice());
    };
  }, []);

  // useEffect(() => {
  //   if (totalData) {
  //     const filteredArray = totalData?.filter((el) => el?.status === "Open");
  //     const scriptWisedata = filteredArray.reduce((acc, el) => {
  //       const key =
  //         (value?.radioAll === 3 && value?.radioClient === 0) ||
  //         (value?.radioAll === 2 && value?.radioClient === 0)
  //           ? el?.user?.[0]?.userId
  //           : el?.symbol;

  //       if (!acc[key]) {
  //         acc[key] = [];
  //       }

  //       acc[key].push(el);
  //       return acc;
  //     }, {});

  //     setPosition(scriptWisedata);
  //   }
  // }, [totalData, value?.radioAll, value?.radioClient]);

  useEffect(() => {
    if (data) {
      let filteredArray = data?.filter((el) => el?.status === "Open");
      let scriptWisedata = {};
      if (value?.radioAll == 3 && value?.radioClient == 0) {
        filteredArray?.map((el) => {
          const userID = `${el?.user?.[0]?.accountName}_${el?.user?.[0]?.userId}`;
          if (!scriptWisedata[userID]) {
            scriptWisedata[userID] = [];
            scriptWisedata[userID].push(el);
          } else {
            scriptWisedata[userID].push(el);
          }
        });
        // console.log("Data Outstanding&clientWise", scriptWisedata);
        const sorted = sortedObj(scriptWisedata, "nameWise");
        setPosition(sorted);
      } else if (value?.radioAll == 3 && value?.radioClient == 1) {
        filteredArray?.map((el) => {
          if (!scriptWisedata[el?.symbol]) {
            scriptWisedata[el?.symbol] = [];
            scriptWisedata[el?.symbol].push(el);
          } else {
            scriptWisedata[el?.symbol].push(el);
          }
        });
        // console.log("Data Outstanding&scriptWise", scriptWisedata);
        const sorted = sortedObj(scriptWisedata, "symbolWise");

        setPosition(sorted);
      } else if (value?.radioAll == 2 && value?.radioClient == 0) {
        data?.map((el) => {
          const userID = `${el?.user?.[0]?.accountName}_${el?.user?.[0]?.userId}`;
          if (!scriptWisedata[userID]) {
            scriptWisedata[userID] = [];
            scriptWisedata[userID].push(el);
          } else {
            scriptWisedata[userID].push(el);
          }
        });
        // console.log("Data all&clientWise", scriptWisedata);
        const sorted = sortedObj(scriptWisedata, "nameWise");
        setPosition(sorted);
      } else {
        data?.map((el) => {
          if (!scriptWisedata[el?.symbol]) {
            scriptWisedata[el?.symbol] = [];
            scriptWisedata[el?.symbol].push(el);
          } else {
            scriptWisedata[el?.symbol].push(el);
          }
        });
        // console.log("Data all&scriptWise", scriptWisedata);
        const sorted = sortedObj(scriptWisedata, "symbolWise");

        setPosition(sorted);
      }
    }
  }, [data, value?.radioAll, value?.radioClient]);

  // function
  const sortedObj = (obj, type) => {
    const keys = Object.keys(obj);
    if (type === "symbolWise") {
      keys.sort((a, b) =>
        obj[a][0].symbolName.localeCompare(obj[b][0].symbolName)
      );
      const sortedObj = {};
      keys.forEach((el) => {
        sortedObj[`${obj[el][0]?.symbolName}_${el}`] = obj[el];
      });
      return sortedObj;
    } else {
      keys.sort((a, b) => a.localeCompare(b));
      const sortedObj = {};
      keys.forEach((el) => {
        sortedObj[el] = obj[el];
      });
      return sortedObj;
    }
  };

  const handleExit = () => {
    let obj = currentPriceRef.current;
    let finalPrice = [];
    for (let key in obj) {
      let arrayData = obj[key]?.map((el) => {
        return {
          id: el?.id,
          tradePrice: 0,
          High: 0,
          Low: 0,
        };
      });
      finalPrice = [...finalPrice, ...arrayData];
    }
    if (finalPrice?.length > 0) {
      let payload = {
        deviceType: "web",
        positionIds: finalPrice,
        tradeIpAddress: userIP,
        type: "CloseAll",
      };
      setClosePositionLoading({ load: true, index: null });
      closePosition(payload);
    } else {
      notification.error({ message: "Please select position before exit" });
    }
  };
  const handleRollover = async () => {
    let obj = currentPriceRef.current;
    let finalPrice = [];
    for (let key in obj) {
      const priceArray = obj[key]?.map((el) => {
        return { id: el?.id, tradePrice: 0 };
      });
      finalPrice = [...finalPrice, ...priceArray];
    }

    if (finalPrice?.length > 0) {
      let payload = {
        deviceType: "web",
        positionIds: finalPrice,
        tradeIpAddress: userIP,
        type: "rollover",
      };
      setRolloverLoading(true);
      try {
        let response = await APICall(
          "post",
          EndPoints.rollOverPosition,
          payload
        );
        if (response.status === 200) {
          currentPriceRef.current = {};
          notification.success({ message: response?.data?.message });
        } else {
          notification.error({ message: response?.data?.message });
        }
      } catch (error) {
        notification.error({ message: error?.message });
        console.log("error", error);
      } finally {
        setRolloverLoading(false);
      }
    } else {
      notification.error({ message: "Please select position before rollover" });
    }
  };

  const closePosition = async (obj, record) => {
    try {
      let response = await APICall("post", EndPoints.closePosition, obj);
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        const userID = `${record?.user?.[0]?.accountName}_${record?.user?.[0]?.userId}`;
        if (
          position?.[record?.symbol]?.length === 1 ||
          position?.[userID]?.length === 1
        ) {
          let filterActiveKey = activeKey?.filter((el) => {
            return Number(el) !== record?.tableIndex;
          });

          setActiveKey(filterActiveKey);
        }

        currentPriceRef.current = {};
      } else {
        notification.error({ message: response?.data?.message, duration: 10 });
      }
    } catch (error) {
      console.log("error", error);
      notification.error({ message: error.message });
    } finally {
      setClosePositionLoading({ load: false, index: null });
    }
  };

  const handleClose = async (record, index) => {
    let obj = {
      deviceType: "web",
      positionIds: [
        {
          id: record?._id,
          tradePrice: 0,
          High: 0,
          low: 0,
        },
      ],
      tradeIpAddress: userIP,
      type: "Close",
    };
    setClosePositionLoading({ load: true, index: index });
    await closePosition(obj, record);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows, key, index) => {
      const positionSelected = selectedRows?.map((el) => {
        return { id: el?._id, symbol: el?.symbol, marketName: el?.marketName };
      });
      currentPriceRef.current[index] = positionSelected;
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === "Close",
    }),
    clearSelectedRows: () => {
      rowSelection.selectedRowKeys = [];
    },
  };

  const TableFooter = () => {
    return (
      <TableFooterContainer>
        <FooterItem>
          <span>C1 (483338)</span>
        </FooterItem>
        <FooterItem>
          <span>-10,145.00</span>
        </FooterItem>
        <FooterItem>
          <span>TOTAL</span>
        </FooterItem>
        <FooterItem>
          <span>-10,145.00</span>
        </FooterItem>
      </TableFooterContainer>
    );
  };

  const handleDate = (date, string, name) => {
    setValue({ ...value, [name]: date });
  };

  const handleClear = () => {
    dispatch(setUserClearFilter(true));
    valueRef.current = {};
    setValue({
      radioClient: loginAccountType === "customer" ? 1 : 0,
      radioAll: 3,
      asOnDate: null,
    });
    setSearchParams({ userId: loginUserId });
  };

  const findTrade = async () => {
    let payloadValue = valueRef.current;
    let valueObj = {
      userId: payloadValue?.userId || loginUserId,
      marketName: payloadValue?.value?.marketName || null,
      symbolName: payloadValue?.value?.symbolName || null,
      asOnDate: value?.asOnDate?.toISOString() || null,
      // valan: payloadValue?.valan || null,
    };

    for (let key in valueObj) {
      if (!valueObj[key]) {
        delete valueObj[key];
      }
    }

    setSearchParams(valueObj);
  };

  const quantityCalculation = (data, type) => {
    const totalBuy = data?.reduce((acc, el) => {
      return acc + (el?.totalBuyQuantity || 0);
    }, 0);

    const totalSell = data?.reduce((acc, el) => {
      return acc + el?.totalSellQuantity;
    }, 0);

    if (type === "buy") {
      return totalBuy;
    } else if (type === "sell") {
      return totalSell;
    } else {
      return totalBuy - totalSell;
    }
  };

  const onChangeRadio = (e) => {
    const value = e.target.value;
    if (value === 0 || value === 1) {
      setValue((pre) => ({ ...pre, radioClient: value }));
    } else {
      setValue((pre) => ({ ...pre, radioAll: value }));
    }
  };

  // column
  const columns = useMemo(() => {
    let array = [
      Table.SELECTION_COLUMN,
      {
        title: "SR No.",
        dataIndex: "index",
        key: "index",
        align: "center",
        width: "4rem",
        render: (text, record, index) => {
          return index + 1;
        },
      },
      {
        title: "SEGMENT",
        dataIndex: "marketName",
        key: "marketName",
        align: "left",
        width: "7rem",
      },
      value.radioClient === 1
        ? {
            title: "CLIENT INFO",
            dataIndex: "client",
            key: "client",
            width: "10rem",
            render: (text, record) => {
              return (
                <span>{`${record?.user?.[0]?.userId} (${record?.user?.[0]?.accountName})`}</span>
              );
            },
          }
        : { width: "0rem" },
      {
        title: "SCRIPT NAME",
        dataIndex: "symbolName",
        key: "symbol",
        align: "left",
        width: "12rem",
        sorter: {
          compare: (a, b) => a?.symbolName?.localeCompare(b?.symbolName),
        },
        render: (text, record) => {
          const date = valanBillDate(record?.expiryDate, false);
          const name = ScriptName(record);
          return (
            <div style={{ textAlign: "left" }}>
              {name} {date}
              {/* {record.marketName !== "FOREX"
                ? `${record?.symbolName} ${date ? date : ""}`
                : record?.symbolName} */}
            </div>
          );
        },
      },
      {
        title: "TYPE",
        dataIndex: "positionType",
        key: "positionType",
        align: "center",
        width: "4rem",
        render: (text, record) => {
          return <div>{record?.positionType}</div>;
        },
      },
      {
        title: "BUY QTY.(LOT)",
        dataIndex: "totalBuyQuantity",
        key: "totalBuyQuantity",
        align: "end",
        width: "10rem",
        render: (text, record) => {
          return (
            // <div>{`${formatToINR(record?.totalBuyQuantity)} (${
            //   record?.totalBuyQuantity && record?.marketName !== "NSE EQU"
            //     ? record?.lot > 0.005
            //       ? record?.lot?.toFixed(2)
            //       : record?.lot
            //     : 0
            // })`}</div>
            <PositionQuantity
              id={record?._id}
              market={record?.marketName}
              type={"totalBuyQuantity"}
              lot={"totalBuyLot"}
            />
          );
        },
      },
      {
        title: "BUY AVG.",
        dataIndex: "totalBuyPrice",
        key: "totalBuyPrice",
        align: "end",
        width: "10rem",
        render: (text, record) => {
          return <PositionAverage id={record?._id} type={"buyAverage"} />;
        },
      },
      {
        title: "SELL QTY.(LOT)",
        dataIndex: "totalSellQuantity",
        key: "totalSellQuantity",
        align: "end",
        width: "10rem",
        render: (text, record) => {
          return (
            // <div>{`${formatToINR(record?.totalSellQuantity)}(${
            //   record?.totalSellQuantity && record?.marketName !== "NSE EQU"
            //     ? record?.lot > 0.005
            //       ? record?.lot?.toFixed(2)
            //       : record?.lot
            //     : 0
            // })`}</div>
            <PositionQuantity
              id={record?._id}
              market={record?.marketName}
              type={"totalSellQuantity"}
              lot={"totalSellLot"}
            />
          );
        },
      },
      {
        title: "SELL AVG.",
        dataIndex: "totalSellPrice",
        key: "totalSellPrice",
        align: "end",
        width: "10rem",
        render: (text, record) => {
          return <PositionAverage id={record?._id} type={"sellAverage"} />;
        },
      },
      {
        title: "OUTSTANDING (LOT)",
        dataIndex: "netQ",
        align: "end",
        key: "netQ",
        width: "10rem",
        render: (text, record) => {
          return (
            // <div>{`${formatToINR(record?.totalQuantity)} (${
            //   record?.marketName !== "NSE EQU"
            //     ? record?.lot > 0.005
            //       ? record?.lot?.toFixed(2)
            //       : record?.lot
            //     : 0
            // })`}</div>
            <PositionQuantity
              id={record?._id}
              market={record?.marketName}
              type={"outstandingQuantity"}
              lot={"outstandingLot"}
            />
          );
        },
      },
      {
        title: "LIVE",
        dataIndex: "cR",
        key: "cR",
        width: "10rem",
        align: "center",
        render: (text, record) => {
          if (record?.status === "Close") {
            return "-";
          } else {
            return (
              <MemorizedPositionData id={record?._id} type={"LiveValue"} />
            );
          }
        },
      },
      {
        title: "P & L",
        dataIndex: "m2M",
        align: "end",
        key: "m2M",
        width: "15rem",
        render: (text, record) => {
          return <PositionSocketData id={record?._id} type={"currentPNL"} />;
        },
      },
      {
        title: "EXIT",
        dataIndex: "close",
        key: "close",
        align: "center",
        width: "5rem",
        render: (imageUrl, record, index) => {
          if (record?.status === "Close") {
            return "-";
          } else {
            return (
              <>
                <Popconfirm
                  title={"Close Position"}
                  description={"Are you sure to close position ?"}
                  onConfirm={() => handleClose(record, index)}
                  placement="topRight"
                >
                  <CloseImage
                    style={{ cursor: "pointer" }}
                    src={closeImage}
                    // onClick={() => handleClose(record, index)}
                    alt="Product"
                  />
                </Popconfirm>
              </>
            );
          }
        },
      },
    ];
    return array;
  }, [closePositionLoading, position, activeKey]);

  const CollapseItem = Object.keys(position)?.map((el, index) => {
    return {
      key: index,
      label: (
        <StyledLabel>
          <div>
            <span>
              {value?.radioClient === 0
                ? `${position[el][0]?.user[0]?.accountName} (${position[el][0]?.user[0]?.userId}) : `
                : `${ScriptName(position[el][0])} 
                   ${
                     position[el][0]?.expiryDate
                       ? valanBillDate(position[el][0]?.expiryDate, false)
                       : ""
                   } 
                    : `}
            </span>

            <TotalPNL data={position[el]} />
          </div>
          <TitleRow>
            <Col xs={24} lg={12}>
              {value?.radioClient === 1 &&
              position[el][0]?.marketName === "NSE EQU"
                ? `Market QTY: `
                : `Market QTY (LOT): `}
              <TotalOutstanding
                data={position[el]}
                value={value}
                type="market"
              />
            </Col>
            <Col xs={24} lg={12}>
              {value?.radioClient === 1 &&
              position[el][0]?.marketName === "NSE EQU"
                ? `Intraday QTY: `
                : `Intraday QTY (LOT): `}
              <TotalOutstanding
                data={position[el]}
                value={value}
                type="intraday"
              />
            </Col>
          </TitleRow>
        </StyledLabel>
      ),
      children: (
        <StyTable
          virtual
          columns={columns}
          rowSelection={{
            ...rowSelection,
            onChange: (selectedRowKeys, selectedRows, key) =>
              rowSelection.onChange(selectedRowKeys, selectedRows, key, index),
          }}
          dataSource={position[el]?.map((user) => ({
            ...user,
            tableIndex: index,
          }))}
          pagination={false}
          sortDirections={["ascend", "descend", "ascend"]}
          // footer={() => <TableFooter />}
          locale={{
            emptyText: isLoading ? <Skeleton active /> : null,
          }}
          scroll={{
            x: 1300,
          }}
          rowKey="_id"
        />
      ),
    };
  });

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };

  return (
    <>
      <ThemeCard>
        <Row
          gutter={[20, 20]}
          justify={"center"}
          style={{ marginBottom: "2rem" }}
        ></Row>
        <Row
          gutter={[10, 15]}
          style={{ textAlign: "center", alignItems: "center" }}
        >
          {loginAccountType !== "customer" && (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={4}
              style={{ marginTop: "0.5rem" }}
            >
              <MarketTypeRadio
                options={options1}
                onChange={onChangeRadio}
                value={value?.radioClient}
                optionType="button"
                buttonStyle="solid"
                width={"46%"}
              />
            </Col>
          )}
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={6}
            xl={4}
            style={{ marginTop: "0.5rem" }}
          >
            <MarketTypeRadio
              options={options2}
              onChange={onChangeRadio}
              value={value.radioAll}
              optionType="button"
              buttonStyle="solid"
              width={"46%"}
            />
          </Col>
          <MarketScriptList valueRef={valueRef} />
          {/* <ValanComponant valueRef={valueRef} /> */}

          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <ThemeDatePicker
              title="As on Date"
              name="asOnDate"
              width="100%"
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
              placeholder="Select Date"
              onChange={(date, string) => handleDate(date, string, "asOnDate")}
              value={value?.asOnDate}
            />
          </Col>

          {loginAccountType !== "customer" && loginAccountType !== "broker" && (
            <UserFilterComponant valueRef={valueRef} />
          )}
        </Row>

        <Row style={{ marginTop: "3rem" }} gutter={[10, 10]}>
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={findTrade}>
              Search
            </StyledButton>
          </Col>
          {loginAccountType !== "broker" && (
            <Col xs={24} sm={12} md={4} lg={3} xl={2}>
              <StyledButton
                onClick={handleRollover}
                type="primary"
                loading={rolloverLoading}
              >
                Rollover
              </StyledButton>
            </Col>
          )}
          {loginAccountType !== "broker" && (
            <Col xs={24} sm={12} md={4} lg={3} xl={2}>
              <StyledButton
                type="primary"
                onClick={handleExit}
                loading={
                  closePositionLoading?.load &&
                  closePositionLoading?.index === null
                }
              >
                Exit Position
              </StyledButton>
            </Col>
          )}
          <Col xs={24} sm={12} md={4} lg={3} xl={2}>
            <StyledButton type="primary" onClick={handleClear}>
              Clear Filter
            </StyledButton>
          </Col>
        </Row>
      </ThemeCard>

      <ThemeCard style={{ marginTop: "2rem" }}>
        <StyleRow gutter={[10, 10]}>
          <Col xs={24} sm={12} md={6} lg={3} xl={3} style={style.colCardText}>
            <CardTitleText>TOTAL MTM</CardTitleText>
            <CardDetailText>
              {data ? <TotalMTM data={data} /> : 0}
            </CardDetailText>
          </Col>

          <Col xs={24} sm={12} md={6} lg={3} xl={3} style={style.colCardText}>
            <CardTitleText>BUY QTY </CardTitleText>
            <CardDetailText>
              {formatToINR(quantityCalculation(data, "buy")) || 0}
            </CardDetailText>
          </Col>
          <Col xs={24} sm={12} md={6} lg={3} xl={3} style={style.colCardText}>
            <CardTitleText>SELL QTY</CardTitleText>
            <CardDetailText>
              {formatToINR(quantityCalculation(data, "sell")) || 0}
            </CardDetailText>
          </Col>
          <Col xs={24} sm={12} md={6} lg={3} xl={3} style={style.colCardText}>
            <CardTitleText>TOTAL QTY</CardTitleText>
            <CardDetailText>
              {formatToINR(quantityCalculation(data, "total")) || 0}
            </CardDetailText>
          </Col>
        </StyleRow>
      </ThemeCard>

      <div style={style.tableWrraperDiv}>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            {Object.keys(position)?.length == 0 ? (
              <Collapse
                style={style.collapse}
                items={[
                  {
                    key: "1",
                    label: <StyledLabel>No data found</StyledLabel>,
                    children: (
                      <StyTable
                        columns={columns}
                        dataSource={[]}
                        pagination={false}
                        locale={{
                          emptyText: isLoading ? <Skeleton active /> : null,
                        }}
                        scroll={{
                          x: 1300,
                        }}
                        rowKey="_id"
                      />
                    ),
                  },
                ]}
              />
            ) : (
              <>
                <Collapse
                  // key={index}
                  activeKey={activeKey}
                  onChange={handleCollapseChange}
                  style={style.collapse}
                  items={CollapseItem}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default TradingPosition;
